@import '../../../styles/variables';

.wallet__wrapper {
  min-height: 100%;
  padding: $inner-lrg 0;

  @media(min-width: 768px) {
    padding: $spacing-med 0;
  }

  .wallet__title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $nxu-font-light;
    margin-bottom: $spacing-sml;

    h1, h2 {
      margin: 0 0 0 $inner-med;
    }

    ion-fab-button {
      margin: 0;
    }
  }

  .wallet__title--action {
    justify-content: flex-start;
  }
}
