// Global Ionic overrides
ion-input {
	label.input-wrapper {
		--padding-top: 3px;
		--padding-bottom: 3px;

		input.native-input {
			padding-left: 5px;
			padding-right: 5px;	
		}
	}
}