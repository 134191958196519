@import '../../../../styles/variables';

$font-size-title: 16px;
$font-size-content: 13px;

.billing-info__next-payment {
  font-size: $font-size-content;

  .billing-info__next-payment__row {
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    &:first-child {
      border-bottom: 1px solid $nxu-border-color;
      padding-bottom: $inner-sml;
      @include from-mobile-med {
        flex-flow: row;
      }
    }
    &:last-child {
      padding-top: $inner-sml;
      border-bottom: none;
    }
  }

  .billing-info__next-payment__col {
    align-self: center;
    ion-button {
      width: 130px;
      @include from-mobile-med {
        width: 210px;
      }
    }
  }

  .billing-info__next-payment__col-cta {
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: $inner-sml;

    @include from-mobile-med {
      display: block;
      margin-top: 0;
    }
  }

  .billing-info__next-payment__full-balance {
    color: red;
  }

  .billing-info__next-payment__freq {
    display: inline-block;
    color: #3171e0;
    background: #3880ff14;
    padding: $inner-xs $inner-sml;
    border-radius: 16px;
  }

  .billing-info__next-payment__due{
    strong {
      display: block;
      font-size: $font-size-title;
    }
  } 

  .billing-info__next-payment__amount {
    font-weight: bold;
    font-size: $font-size-title;

    &.green {
      color: green;
    }

    &.red {
      color: $nxu-danger;
    }
  }
  .billing-info__next-payment__title{
    display: flex;
    h6 {
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    ion-button{
      width: 28px;    
      height: 28px;
      align-self: center;
      margin-left: $inner-xs;
      --padding-end: 2px;
      --padding-start: 2px;
      --padding-top: 2px;
      --padding-bottom: 2px;
    }
  }

  .billing-info__next-payment__warning{
    margin-top: $inner-xs;
    color: $nxu-danger;
    font-size: $nxu-font-size-sml;
  }
}

.billing-info__invoice-history {
  font-size: $font-size-content;

  h3 {
    margin-top: 0;
  }

  .billing-info__invoice-help {
    color: #848484;
  }
}

.invoice-item {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: $inner-med 0;  
  border-bottom: 1px solid $nxu-border-color;

  .invoice-item__col {
    &:first-child {
      padding-right: $inner-xs;
    }
    
    .invoice-item__name {
      text-transform: capitalize;
      font-weight: 600;
    }

    .invoice-item__status {
      margin-top: $inner-xs;
      display: flex;
      align-items: center;
      line-height: 1em;

      > span {
        min-height: 18px;
        display: flex;
        align-items: center;

        @include from-mobile-med {
          min-height: 24px;
        }
      }
    }

    .invoice-item__col--unpaid {
      color: $nxu-danger;
    }

    ion-icon {
      margin-right: $inner-xs;
      font-size: 18px;
      min-width: 18px;

      @include from-mobile-med {
        margin-right: $inner-xs;
        font-size: 24px;
        min-width: 24px;
      }
    }

    .invoice-item__amount {
      font-weight: bold;
      line-height: 1em;
      text-align: right;

      @include from-mobile-med {
        min-width: 50px;
      }

      &.invoice-item__amount--voided {
        text-decoration: line-through
      }
    }

  }

  .invoice-item__col--action {
    display: flex;
    flex-flow: row;
    align-items: center;
    padding-top: $inner-xs;

    @include from-mobile-med {
      padding-top: 0;
    }
  
    ion-button {
      margin: 0 $inner-xs 0 0;
    }

  }

  .invoice-item__download-cta {
    > div {
      margin: 0 6px 0 0;
    }
    ion-icon {
      margin-right: 0;
    }
  }
}

.billing-info__payment-fab--sticky {
  position: sticky;
  display: block;
  bottom: 0;
  text-align: right;

  .billing-info__payment-fab {
    position: absolute;
    bottom: 10px;
    right: -10px;
  }
}

ion-button#wallet-funds-tooltip-trigger, ion-button.invoice-refund-tooltip-trigger, ion-popover.relaxed-balance-tooltip-trigger  {
  width: auto;
  height: auto;
  margin-left: $inner-xs;
  --padding-end: 3px;
  --padding-start: 3px;
  --padding-top: 3px;
  --padding-bottom: 3px;
}

ion-button.invoice-refund-tooltip-trigger {
  svg {
    width: 28px;
    fill: $nxu-bg;
  }
}

ion-popover.wallet-funds-tooltip, ion-popover.invoice-refund-tooltip, ion-popover.relaxed-balance-tooltip-trigger {
  --background: #efefef;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  
  p {
    padding: $inner-med;
    margin: 0;
  }
}

.billing-info__partner-sponsored-message {
  font-size: 1.1rem;
  font-weight: 500;
}
