
.react-tabs {
    -webkit-tap-highlight-color: transparent;

    &__tab-list {
        display: flex;
        flex: 1;
        flex-flow: row;
        justify-content: center;

        border-bottom: none;
        padding: 0;
        margin-bottom: $spacing-sml;
    }

    &__tab {
        flex: 1;

        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        text-align: center;
        text-transform: uppercase;
        border: none;
        padding: 6px $inner-xs;
        border-radius: 0;
        color: #fff;
        border: 2px solid transparent;

        &--selected {
            background: transparent;
            color: $nxu-yellow;
            border-bottom-color: $nxu-yellow;
        }

        &:focus {
            outline: initial;
            border-color: $nxu-yellow;
            &:after {
                height: 0 !important;
            }
        }

        ion-icon {
            font-size: 24px;
            margin-bottom: $inner-xs;
        }
    }
}