@import '../../../styles/variables';

.learner-external {
    .learner-external__card-panel {
        .learner-external__card-panel-title {
            text-align: center;

            @include from-tablet {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 28px;
            }


            img {
                display: block;
                width: 110px;
                margin: 0 auto;

                @include from-tablet {
                    width: 140px;
                }
            }

            h3 {
                display: block;
                margin: 0 auto $inner-med;
                font-size: 1.6em;
                font-weight: bold;

                @include from-tablet {
                    margin: 0;
                }
            }
        }
    }

    span.learner-external__card-panel-chip {
        display: inline-block;
        font-size: 0.85em;
        font-weight: 600;
        padding: 4px $inner-xs;
        border-radius: $nxu-border-radius-sml;
        border: 2px solid $nxu-font-primary;
        color: $nxu-font-primary;
        margin-bottom: $inner-med;

        @include from-tablet {
            margin-left: $inner-xs;
            margin-bottom: 0;
        }
    }

    p.learner-external__card-panel-desc {
        text-align: center;
        margin: 0 0 $spacing-sml;

        @include from-tablet {
            text-align: left;
        }
    }

    .learner-external__card-panel-countdown {
        text-align: center;
        font-weight: $nxu-font-weight-heavy;
        font-style: italic;
        font-size: 1.2rem;
    }

    .learner-external__card-panel-cta {
        display: flex;
        flex-flow: column;
        max-width: 650px;
        margin: 0 auto;
    }
}