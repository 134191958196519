.file-download-button {
  height: 40px;
  min-width: 40px;
  border-radius: 50%;

  ion-icon {
    font-size: 28px;
  }

  &:hover {
    background: #000;
    ion-icon {
      color: rgb(239, 239, 239);
    }
  }
}