@import '../../../styles/variables';

.card-panel.program-summary-card {
    position: relative;

    &.program-summary-card--gpa {
        padding-top: 80px;
        margin-top: 70px;
    }

    .program-summary-card__title-row {
        margin: 0 0 $inner-med;
        border-bottom: 1px solid $nxu-border-color;

        h2 {
            text-align: center;
            font-weight: bold;
            padding: 0 $inner-sml $inner-sml;
            margin: 0;
        }

        p {
            text-align: center;
            padding: 0 $inner-sml $inner-sml;
            margin: 0;
        }

        span {
            &:first-child {
                padding-left: 5px;
            }
            &:not(:last-child)::after {
                display: inline-block;
                padding: 0 5px;
                margin: 0;
                content: '\2022';
            }
        }
    }

    .program-summary-card__columns {
        display: flex;
        flex-direction: column;

        @include from-mobile-med {
            flex-direction: row;
            align-items: center;
        }
    }

    .program-summary-card__left.program-summary-card__graph {
        text-align: center;
        border-bottom: 1px solid $nxu-border-color;
        padding: $inner-med;

        @include from-mobile-med {
            flex-grow: 1;
            min-width: 160px;
            border-bottom: 0;
            border-right: 1px solid $nxu-border-color;
        }

        svg {
            width: 180px;
            max-width: 100%;

            @include from-mobile-med {
                width: 220px;
            }
        }

        svg ~ div {
            margin-top: auto !important;
            top: 0 !important;
        }
    }

    .program-summary-card__graph-details {
        span {
            display: block;
            font-size: 0.9rem;

            @include from-mobile-sml {
                font-size: 0.8rem;
            }

            @include from-mobile-med {
                font-size: 1rem;
            }
        }

        span:first-child {
            font-size: 2.4rem;

            @include from-mobile-sml {
                font-size: 2rem;
            }

            @include from-mobile-med {
                font-size: 3rem;
            }
        }
    }

    .program-summary-card__right.program-summary-card__list {
        display: flex;
        justify-content: center;
        padding: $inner-med;

        @include from-mobile-med {
            flex-grow: 2;
        }

        .program-summary-card__list-inner {
            max-width: 440px;
            text-align: center;
        }

        h3 {
            font-size: 18px;
            font-weight: bold;
            margin: 0 0 $inner-xs;
        }

        ion-button {
            width: 100%;
        }

        .courses-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .courses-list__item {
            font-size: 1.1rem;
            font-weight: $nxu-font-weight-heavy;
            padding: 6px 12px;
            margin: 0 6px 6px;
            border-radius: 5px;
            background: $nxu-yellow;
        }
    }

    .program-summary-card__empty {
        text-align: center;
    }
}

.program-summary-card__gpa-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    border: 5px solid $nxu-font-dark;
    background: $nxu-yellow;
    margin: $spacing-med auto;

    position: absolute;
    top: -90px;
    left: calc(50% - 60px);

    div {
        color: $nxu-font-dark;
        font-weight: bold;
        text-align: center;
    }

    .program-summary-card__gpa-value {
        font-size: 48px;

        span:last-child {
            font-size: 24px;
        }
    }

    .program-summary-card__gpa-sub {
        display: block;
        font-size: 12px;
    }
}

.congratulation-block_wraper {
    position: relative;
    display: inline-block;
    left: -10px;

    @include from-mobile-sml {
        left: -10px;
        top: -40px;
    }

    @include from-desktop-sml {
        left: -70px;
        top: -30px;
    }
}

.congratulation-icon {
    width: 45px;
    height: 45px;
    font-size: 2.2rem;
    left: -10px;
    top: -4%;

    @include from-mobile-sml {
        width: 60px;
        height: 60px;
        font-size: 3rem;
        left: 0px;
        top: -15%;
    }

    @include from-desktop-sml {
        width: 80px;
        height: 80px;
        font-size: 4rem;
        left: 0px;
        top: -15%;
    }

    border-radius: 50%;
    background-color: $nxu-yellow;
    border: 3px solid $nxu-font-dark;
    display: block;
    position: absolute;
}

.congratulation-text {
    display: inline-block;
    background: $nxu-font-dark;
    color: $nxu-yellow;
    padding: 5px 10px 5px 35px;
    margin-top: 2px;
    border-radius: 50px 20px 20px 50px;
    font-size: 1.2rem;
    width: 108%;

    @include from-mobile-sml {
        font-size: 1.5rem;
        padding: 5px 10px 5px 60px;
        width: 106%
    }

    @include from-desktop-sml {
        font-size: 2.2rem;
        width: 130%;
        padding: 5px 10px 5px 80px;
    }
}

.subcol {
    display: inline-block;
    width: 50%;
    position: relative;
    top: 0;
    padding-top: -10px;

    @include from-mobile-sml {
        width: 60%;
    }
}

.subcol_2 {
    display: inline-block;
    width: 30%;
    top: -20px;
    left: 20px;
    position: relative;

    @include from-mobile-sml {
        left: 25px;
    }
}

