@import '../../../../styles/variables';

.payment-info__wrapper {
  padding-bottom: 0;

  .payment-info__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
  }

  .payment-info__col {
    padding-bottom: $inner-med;
  }

  .payment-info__balance, .payment-info__date {
    span {
      display: block;

      &:last-child {
        font-size: 1.1rem;
        font-weight: bold;
      }
    }
  }

  &.payment-info__form {
    .payment-info__row {
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      @include from-mobile-med {
        flex-direction: row;
      }

    }

    .payment-info__row--exchange-rate {
      padding-bottom: $inner-med;
      
      @include from-mobile-med {
        padding-left: $inner-xl;
      }

      span {
        color: $nxu-font-dark-secondary;
        font-size: 0.75rem;
      }
    }

    .payment-info__col {
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;

      &:first-child {
        margin-right: $inner-sml;
      }
    }

    .payment-info__col--exchange-rate {
      position: relative;
      top: -8px;
      border: 1px solid $nxu-border-color;
      border-radius: $nxu-border-radius-sml;
      padding: $inner-sml;

      span {
        font-weight: bold;
      }
    }

    ion-input {
      --background: #fff;
      min-width: 200px;
      max-width: 250px;
    }

    ion-button {
      margin: 0;
    }
  }

  .payment-info__field-item {
    display: flex;
    align-items: center;

    > span {
      display: block;
      margin-right: 6px;
      margin-top: 2px;
    }
  }

  .payment-info__set-amount {
    ion-icon {
      display: block !important;
    }
  }
}
