@import '../../../../styles/variables';

.privacy-page__content {
  h1 {
    text-align: center;
    border-bottom: 1px solid $nxu-border-color;
    margin-top: 0;
    padding-bottom: $inner-sml;
  }
}
.privacy-setting-form {

  .privacy-setting-form__section {
    padding-bottom: $inner-lrg;
    border-bottom: 1px solid $nxu-border-color;
    margin-bottom: $inner-lrg;
  }

  .privacy-setting-form__section-title {
    font-weight: $nxu-font-weight-heavy;
    font-size: 20px;
    margin: 0 0 $inner-xs;
  }

  .privacy-setting-form__section-subtitle {
    margin: $inner-xs 0;
  }

  ion-checkbox {
    display: block;

    &::part(label) {
      text-overflow: initial;
      white-space: break-spaces;
    }
  }

  .privacy-setting-form__contacts-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .privacy-setting-form__contacts-row {
    position: relative;
    padding-bottom: $inner-xs;

    > * {
      margin-bottom: $inner-xs;
    }

    ion-input {
      width: calc(100% - 62px);
    }

    ion-button {
      position: absolute;
      right: 0;
      top: 0;
    }

    @include from-mobile-med {
      display: flex;
      gap: $inner-xs;
      align-items: start;
      padding-bottom: 0;

      ion-button {
        position: initial;
        margin-top: 8px;
      }
    }

    ion-input:last-child {
      margin-right: 52px;
    }
  }
}