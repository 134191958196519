@import '../../../styles/variables';

.agreement__no-request {
  padding-top: 200px;
  text-align: center;
  color: #fff;
}

.agreement__wrapper {
  margin: 0 auto;
  padding: $spacing-sml 0;
  text-align: center;

  @include from-mobile-med {
    padding: $spacing-lrg 0;
  }

  &.agreement__wrapper--hidden {
    display: none;
    visibility: hidden;
  }

  .agreement__document {
    padding: 0 $inner-xs;
    margin: 0 0 $spacing-sml;
    @include from-mobile-med {
      padding: 0;
    }
  }  
}

.agreement__form {
  margin-bottom: 0;
  
  .agreement__form-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $inner-med;
    
    h3 {
      margin: 0 $inner-xs 0 0;
      font-size: 16px;
      font-weight: bold;
    }
    
    ion-button#enrollment-tooltip-trigger {
      --padding-end: 1px;
      --padding-start: 1px;
    }
  }

  .agreement__form-fields {
    div:first-child {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    ion-input {
      max-width: 300px;
      width: 100%;
      margin: 0 0 $inner-sml;
      --background: #fff;

      @include from-mobile-med {
        margin: 0 $inner-sml $inner-sml;
      }
    }
  }

  .agreement__form-toolbar {
    padding:  0;
  
    ion-button {
      display: block;
      max-width: 300px;
      margin: 0 auto $inner-xl;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

ion-popover.enrollment-tooltip {
  --background: #efefef;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  
  p {
    padding: $inner-med;
    margin: 0;
  }
}