@import '../../../styles/variables';

.mynxu-animation__wrapper {
    max-width: 350px;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    @include from-mobile-med {
        width: auto;
    }
}