.linkedin-button-logo-wrapper {
    width: 15px;
    height: 15px;
    position: absolute;
    left: 9px;
    top: 7px;
}

.linkedin-button-text {
    color: white;
    font-size: 15px;
    padding: 3px 0px 0px 22px;
    text-decoration: none;
    font-family: Arial, sans-serif;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.linkedin-share-button {
    width: 100px;
    background-color: #0a66c2;
    color: white;
    position: relative;
    height: 30px;
    text-align: center;
    font-family: Arial, sans-serif;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.linkedin-share-button:hover {
    background-color: #005582;
    outline: none;
}