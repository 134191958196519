@import '../../../styles/variables';

.get-in-touch {
  p {
    margin: 0 0 $inner-sml;
    text-align: center;
  }

  .get-in-touch__list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $inner-lrg;
  }

  .get-in-touch__item {
    display: inline-block;
    margin: 0 $inner-sml;

    ion-icon {
      font-size: 38px;
    }
  }

}

