@import '../../../styles/variables';

.card-panel.deadlines-card {
    position: relative;
    margin-bottom: 0;

    .program-summary-card__title-row {
        margin: 0 0 $inner-med;
        border-bottom: 1px solid $nxu-border-color;

        h2 {
            text-align: center;
            font-weight: bold;
            padding: 0 $inner-sml $inner-sml;
            margin: 0;
        }

        p {
            text-align: center;
            padding: 0 $inner-sml $inner-sml;
            margin: 0;
        }
    }
}

ul.deadlines-list {
    list-style: none;
    padding-left: 0;
    border-top: 1px solid $nxu-border-color;

    li.deadlines-list-item {
        border-bottom: 1px solid $nxu-border-color;
        padding: $inner-xs;

        &.deadlines-list-item--due {
            > * {
                color: $nxu-danger;
            }
        }

        > * {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $nxu-font-dark;
        }

        a {
            text-decoration: none;
        }

        .deadlines-list-item__label {
            display: flex;
            align-items: center;
            align-content: center;
            line-height: 1em;
        }

        .deadlines-list-item__label--overdue {
            display: block;
            font-weight: bold;
        }

        .deadlines-list-item__label > span {
            @include from-mobile-med {
                display: flex;
                align-items: center;
            }

            .deadlines-list-item__label--overdue {
                padding-bottom: 4px;

                @include from-mobile-med {
                    padding-bottom: 0;
                    padding-right: 4px;
                }
            }
        }

        ion-icon {
            display: block;
            margin-right: $inner-xs;
            font-size: 24px;
            min-width: 24px;
        }

        .deadlines-list-item__date {
            margin-left: $inner-xs;
            text-align: right;
        }
    }
}
