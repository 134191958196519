@import '../../../styles/variables';

ion-header.learner-path-modal__header {
  ion-toolbar {
    --background: #ffff54;
    --color: black;
  }
  ion-button {
    margin-right: $inner-sml;
  }

  ion-title {
    font-size: $nxu-font-size-reg;
    font-weight: $nxu-font-weight-heavy;
  }
}

ion-content.learner-path-modal__content {
  --background: #fff;

  .learner-path-modal__content-inner {
    padding: $inner-med;
  }

  h2 {
    font-size: $nxu-font-size-med;
    font-weight: $nxu-font-weight-heavy;
    text-align: center;
    margin: 0 0 $inner-med;
    padding: $inner-xs $inner-med $inner-med;
    border-bottom: 2px solid $nxu-border-color;
  }

  .learner-path-modal__description {
    margin: 0 0 $inner-med;
    padding: 0 $inner-med $inner-med;
    border-bottom: 2px solid $nxu-border-color;
  }
  
  .learner-path-modal__skills{
    padding: 0 $inner-med $inner-med;
  }

  .skills-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }

  .skills-list__item {
    font-size: $nxu-font-size-sml;
    font-weight: $nxu-font-weight;
    padding: 6px $inner-sml;
    margin: 0 $inner-sml $inner-sml 0;
    border-radius: $nxu-border-radius-sml;
    background: #efefef;
  }

  h3 {
    font-weight: $nxu-font-weight-heavy;
    font-size: $nxu-font-size-reg;
    margin: 0 0 $inner-sml;
  }

  p {
    margin: 0;
  }
}

ion-footer.learner-path-modal__footer {
  ion-toolbar {
    --background: #efefef;
    text-align: center;
  }
  ion-button {
    margin: $inner-sml 0;
  }
}