@import '../../../../styles/variables';

section.dashboard {

    h1 {
        color: $nxu-font-light;
        margin: $spacing-med 0 $spacing-sml;
    }

    .dashboard__early-adopter-icon {
        padding: $inner-med $inner-med $inner-xs;
        text-align: center;

        svg {
            height: 200px;

            @include from-mobile-med {
                height: 300px;
            }
        }
    }

    .dashboard__early-adopter-welcome {
        margin-bottom: 0;
        padding: $inner-med;

        h1 {
            margin-top: 0;
            font-size: 1.15rem;
            font-weight: 600;
        }

        p {
            font-size: 0.95rem;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .dashboard-page__referral {
        padding-top: $spacing-sml;
        padding-bottom: $spacing-sml;
    }
}