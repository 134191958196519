@import '../../../../styles/variables';

.profile-page__documents {
    h2 {
        margin: 0 0 $inner-med;
    }

    .profile-page__documents-success {
        margin: $inner-lrg auto;
    }
}

.id-document-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $inner-sml;

    &:not(:last-child) {
        border-bottom: 1px solid $nxu-border-color;
        margin-bottom: $inner-sml;
    }      

    .id-document-item__column {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .id-document-item__status-icon {
        font-size: 24px;
        margin-right: $inner-sml;
        min-width: 24px;

        &.id-document-item__status-icon--Passed {
            color: $nxu-font-primary;
        }

        &.id-document-item__status-icon--NotReviewed {
            color: $nxu-warn;
        }

        &.id-document-item__status-icon--Failed {
            color: $nxu-danger;
        }
    }

    .id-document-item__details {
        display: flex;
        flex-direction: column;
        user-select: text;
    }

    .id-document-item__details-title {
        font-weight: $nxu-font-weight-heavy;
    }

    .id-document-item__details-status {
        &.id-document-item__details-status--Passed {
            color: $nxu-font-primary;
        }
        
        &.id-document-item__details-status--NotReviewed {
            color: $nxu-warn;
        }

        &.id-document-item__details-status--Failed {
            color: $nxu-danger;
        }
    }
}