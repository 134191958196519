@import '../../../styles/variables';

.page-content {
    width: calc(100% - 32px);
    max-width: $nxu-page-width;
    min-height: calc(100% - 70px);
    margin: 0 auto;

    @include from-tablet {
        width: calc(100% - 60px);
    }

    &.page-content__full {
        width: 100%;
        max-width: initial;
    }
}

ion-menu.nxu-desktop-nav {
	--ion-toolbar-background: #ffff54;
    --ion-background-color: #efefef;

    &::part(backdrop) {
        background-color: rgba(10, 10, 10, 0.5);
    }

    &::part(container) {
        box-shadow: 4px 0px 16px rgba(38, 38, 38, 1);
    }

    ion-item {
        cursor: pointer;
        ion-label {
            padding-left: $inner-xs;
        }

        &.nxu-desktop-nav--active  {
            --color: #098b5b;

            ion-icon {
                color: $nxu-font-primary;
            }
        }
    }
}

ion-footer.nxu-mobile-nav {
    nav {
        display: flex;
        justify-content: space-evenly;
    }

    ion-button {
        height: 48px;
        margin: $inner-xs 0;
        div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        ion-icon {
            font-size: 1.5rem;
            padding-bottom: $inner-xs;
        }
        ion-label {
            font-size: 0.7rem;
        }

        &.nxu-mobile-nav--active {
            ion-label, ion-icon {
                color: $nxu-yellow;
            }
        }
    }
}

div.nxu-footer {
    width: 100%;
    height: 46px;
    background: #262626;

    div.nxu-footer__toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1140px;
        height: 100%;
        margin: 0 auto;
        padding: $inner-xs $inner-med;
    }

    .nxu-footer__title, a {
        color: $nxu-font-light;
        font-size: 0.75rem;
    }

    .nxu-footer__title {
        font-weight: $nxu-font-weight;
        text-align: center;
        padding: 0 $inner-xs;
        max-height: 50px;
        overflow: hidden;
    }
}

  
