/* Media breakpoints */
$screen-smallest: 400px !default;
$screen-xs-min: 576px !default;
$screen-sm-min: 768px !default;
$screen-md-min: 992px !default;
$screen-lg-min: 1200px !default;


@mixin from-mobile-sml {
    @media (min-width: #{$screen-smallest}) {
        @content
    }
}

@mixin from-mobile-med {
    @media (min-width: #{$screen-xs-min}) {
        @content
    }
}

@mixin from-tablet {
    @media (min-width: #{$screen-sm-min}) {
        @content
    }
};

@mixin from-desktop-sml {
    @media (min-width: #{$screen-md-min}) {
        @content
    }
};

// Fonts
$nxu-font-main: "Roboto", "Helvetica", "Arial", sans-serif;
$nxu-font-weight: 400;
$nxu-font-weight-heavy: 600;
$nxu-line-height: 1.3;

$nxu-font-size-xs: 0.75rem; // 12px
$nxu-font-size-sml: 0.85rem; // 13px
$nxu-font-size-reg: 1rem; // 16px
$nxu-font-size-med: 1.2rem; // 19px

// Default spacing for margins through the app
$spacing-sml: 24px;
$spacing-med: 36px;
$spacing-lrg: 48px;
$spacing-xl: 60px;
$spacing-xxl: 120px;

// Smaller default spacing for inner padding
$inner-xs: 8px;
$inner-sml: 12px;
$inner-med: 16px;
$inner-lrg: 20px;
$inner-xl: 24px;

$nxu-border-radius: 12px;
$nxu-border-radius-sml: 5px;

$nxu-page-width: 960px;

// Default colours for app
$nxu-yellow: #ffff54;
$nxu-bg: #262626;
$nxu-bg-contrast: #efefef;
$nxu-border-color: #d3d3d3;
$nxu-field-bg: #fff;
$nxu-font-light: #fff;
$nxu-font-dark-secondary: #444;
$nxu-font-dark: #000;
$nxu-font-primary: #098b5b;
$nxu-danger: #eb445a;
$nxu-warn: #ff8c00 ;