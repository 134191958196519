@import '../../../styles/variables';

.success-wrapper {
  height: 100%;
  padding-top: $spacing-lrg;

  .success-wrapper__inner {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 480px;
    margin: 0 auto $spacing-sml;
    text-align: center;

    h4 {
      font-size: 25px;
      font-weight: 400;
      margin-bottom: $inner-med;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: $inner-med;
      padding: 0 $inner-lrg;
    }

    .success-icon {
      background: url('../../../assets/img/ok.svg') no-repeat;
      background-size: cover;
      height: 70px;
      margin: $inner-lrg auto;
      width: 70px;
    }
  }
}