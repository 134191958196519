@import '../../../../styles/variables';

.stripe-card__form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.stripe-card__fields {
  background-color: $nxu-field-bg;
  border: 1px solid $nxu-border-color;
  border-radius: $nxu-border-radius-sml;
  padding: $inner-sml;
}

.stripe-card__error {
  padding: $inner-sml;
}

// ToDo: Duplicate of toggle on cardholder-details, should this type of checkbox be a re-usable component
ion-item.stripe-card__save {
  margin: $inner-sml 0 0;
  --background: #fff;
  --border-color: #d3d3d3;

  ion-checkbox {
    margin: $inner-sml $inner-sml $inner-sml 0;
  }
  ion-label {
    margin: $inner-sml 0;
  }
}

.stripe-card__action {
  margin-top: $inner-sml;
}