@import '../../../styles/variables';

ion-header.upload-modal__header {
    ion-toolbar {
      --background: #ffff54;
      --color: black;
    }
    ion-button {
      margin-right: $inner-sml;
    }
}
  
ion-content.upload-modal__content {
    --background: #efefef;
}

.upload-modal__content {
    .upload-modal__content-inner {
      padding: $spacing-sml;
    }
  
    p {
        margin-top: 0;
    }
    ul {
      list-style:circle;
      padding-left: $inner-lrg;
      margin-bottom: $inner-xl;
    }
    li {
      margin-bottom: $inner-xs;
    }
    img {
        display: block;
        width: 300px;
        max-width: 100%;
        margin: 0 auto;
    }
  }

.upload-modal__form {
    ion-button.upload-modal__form-submit {
        display: block;
        width: 500px;
        max-width: 100%;
        margin: 0 auto;
    }
}

.upload-modal__field-wrapper {
    margin: 0 auto $inner-lrg;
    max-width: 500px;

    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #d3d3d3;
        border-radius: $nxu-border-radius-sml;
        padding: $inner-xs;
    }

    &.upload-modal__field-wrapper--error {
        > div {
            border-color: $nxu-danger;
        }

        ion-note {
            display: inline-flex;
            margin-top: 5px;
            margin-right: $inner-sml;
            margin-left: $inner-sml;            
            margin-bottom: $inner-sml;
        }
    }
}