@import '../../../../styles/variables';

$bar-height: 28px;

.tuition-cap {

  .tuition-cap__title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $inner-sml;

    h3 {
      margin: 0;
    }
  }

  .tuition-cap__progress-row {
    padding: $inner-med 0 $inner-sml;
  }

  .tuition-cap__totals-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $inner-xs;
  }
}

.tuition-cap__progress-bar {
  position: relative;
  width: 100%;
  height: $bar-height;
  background: $nxu-border-color;
  border-radius: $nxu-border-radius;

  .tuition-cap__progress-bar-inner {
    position: absolute;
    height: 100%;
    background: $nxu-font-primary;
    border-radius: $nxu-border-radius;
  }

  .tuition-cap__progress-bar-numeric {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 40px;
    height: $bar-height;
    font-size: 16px;
    font-weight: bold;
    color: white;

    &.tuition-cap__progress-bar-numeric--right {
      color: $nxu-font-dark;
    }
  }
}

ion-button#tuition-cap-tooltip-trigger  {
  width: auto;
  height: auto;
  margin-left: $inner-xs;
  --padding-end: 3px;
  --padding-start: 3px;
  --padding-top: 3px;
  --padding-bottom: 3px;
}

ion-popover.tuition-cap-tooltip {
  --background: #efefef;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);

  p {
    padding: $inner-med;
    margin: 0;
  }
}