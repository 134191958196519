@import "../../../styles/variables";

// ToDo: Setup common typography for H-tags
.learner-path {
  .learner-path__title {
    margin-bottom: $inner-med;
    color: $nxu-font-light;

    h2 {
      margin: 0;
      font-weight: $nxu-font-weight-heavy;
    }

    p {
      margin: $inner-xs 0 0;
    }

    span {
      &:first-child {
        padding-left: 5px;
      }
      &:not(:last-child)::after {
        display: inline-block;
        padding: 0 5px;
        margin: 0;
        content: "\2022";
      }
    }
  }

  .learner-path__empty {
    text-align: center;
    font-weight: $nxu-font-weight-heavy;
  }

  .learner-path__list-wrapper {
    background: $nxu-bg-contrast;
    border: 1px solid $nxu-border-color;
    border-radius: $nxu-border-radius;
    padding: $inner-sml;
    margin-bottom: $spacing-sml;

    @include from-mobile-med {
      text-align: left;
    }

    .learner-path__list-title {
      display: flex;
      justify-content: space-between;
      margin: 4px 0 $inner-sml;
    }

    h2 {
      margin: 0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  .learner-path__item {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;

    border-top: 1px solid $nxu-border-color;
    text-align: left;
    font-size: 14px;
    padding: $inner-sml 0;
    margin-bottom: 0;

    > button {
      width: 100%;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      background-color: $nxu-bg-contrast;
    }

    > div,
    > button > div {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: start;
    }
  }

  .learner-path__item-transfer {
    ion-icon.learner-path__item-transfer__icon {
      font-size: 22px;
      padding: 2px;
      margin: 0 32px 0 12px;
      background: $nxu-yellow;
      border-radius: $nxu-border-radius-sml;
    }
  }

  .learner-path__item-chip-container {
    display: flex;
    flex-direction: row;
    column-gap: $inner-xs;
    align-items: center;
  }

  .learner-path__item-chip {
    font-size: $nxu-font-size-xs;
    font-weight: 600;
    padding: 4px $inner-xs;
    border-radius: $nxu-border-radius-sml;
    text-align: center;

    &.learner-path__item-chip--id {
      min-width: 76px;
      background: $nxu-yellow;
    }

    &.learner-path__item-chip--grade,
    &.learner-path__item-chip--status,
    &.learner-path__item-chip--availability {
      border: 2px solid;
      margin-left: $inner-xs;
      width: 100%;
      max-width: 90px;

      > span {
        display: block;
        font-weight: 400;
        color: $nxu-font-dark-secondary;
      }
    }

    &.learner-path__item-chip--grade {
      max-width: 90px;
      border: none;
      color: $nxu-font-primary;
      font-size: 24px;
      line-height: 1em;

      > span {
        font-size: $nxu-font-size-xs;
        line-height: 1em;
        color: $nxu-font-primary;
      }

      &.learner-path__item-chip--grade-failed {
        color: $nxu-danger;

        > span {
          color: $nxu-danger;
        }
      }
    }

    &.learner-path__item-chip--status {
      border-color: $nxu-font-primary;
      color: $nxu-font-dark;
    }

    &.learner-path__item-chip--availability {
      border-color: $nxu-font-dark;
      color: $nxu-font-dark;
    }
  }

  .learner-path__item-chips {
    justify-content: end !important;
    width: 160px;
    .learner-path__item-chip--availability {
      flex: 0 0 90px;
    }
  }

  .learner-path__canvas-block {
    text-align: center;
  }
}

ion-button#seminars-tooltip-trigger {
  width: auto;
  height: auto;
  margin-left: $inner-xs;
  --padding-end: 3px;
  --padding-start: 3px;
  --padding-top: 3px;
  --padding-bottom: 3px;
}

ion-popover.seminars-tooltip {
  --background: #efefef;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);

  p {
    padding: $inner-med;
    margin: 0;
  }
}

ion-popover.capstone-tooltip {
  --background: #efefef;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);

  p {
    padding: $inner-med;
    margin: 0;
  }
}
