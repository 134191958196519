@import '../../../styles/variables';

.aep-document-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $inner-sml;

    &:not(:last-child) {
        border-bottom: 1px solid $nxu-border-color;
        margin-bottom: $inner-sml;
    }      

    .aep-document-item__column {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .aep-document-item__description {
        color: $nxu-font-dark-secondary;
    }

    .aep-document-item__status-icon {
        font-size: 24px;
        margin-right: $inner-sml;
        min-width: 24px;
        color: $nxu-danger;

        &.aep-document-item__status-icon--ok {
            color: $nxu-font-primary;
        }
    }

    .aep-document-item__details {
        display: flex;
        flex-direction: column;
        user-select: text;
    }


    .aep-document-item__details-title {
        font-weight: $nxu-font-weight-heavy;
    }

    .aep-document-item__details-status {
        color: $nxu-danger;

        &.aep-document-item__details-status--ok {
            color: $nxu-font-primary;
        }
    }
}