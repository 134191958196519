@import '../../../styles/variables';

section.login {
    .login__welcome-icon {
        margin: $spacing-xl 0;
        text-align: center;

        svg {
            height: 200px;

            @include from-mobile-med {
                height: 300px;
            }
        
        }
    }

    .login__exit-message {
        margin: $spacing-xl auto;
        text-align: center;
        color: $nxu-font-light;
    }

    .login__cta-wrapper {
        max-width: 400px;
        width: 90%;
        margin: $spacing-lrg auto;
        text-align: center;

        h1 {
            margin-top: 0;
            margin-bottom: $inner-xl;
            font-size: 22px;
        }
    }
}