@import '../../../styles/variables';

.academics-page {
    h1 {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: $spacing-sml;
        text-align: center;
        color: #fff;
        
        ion-icon {
            padding-right: $inner-med;
        }
    }

    .academics-page__welcome-block {
        margin-top: $spacing-xl;
        h2 {
            margin: $inner-sml 0;
        }
    }
}