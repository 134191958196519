@import '../../../styles/variables';

ion-toolbar.header__titlebar {
  --background: #ffff54;
  padding: 0 $inner-sml;
  @include from-mobile-med {
    padding: 0 $inner-med;
  }

  ion-title {
    padding: $inner-sml 4px $inner-sml 0;

    .header-logo-wrapper {
      display: flex;
      align-items: center;
    }

    svg {
      height: 40px;
    }
  }

  ion-button.nxu-header__profile-button {
    border-radius: 20px;
    background-color: $nxu-bg;
    --padding-top: 0 !important;
    --padding-bottom: 0 !important;
    --padding-start: 0 !important;
    --padding-end: 0 !important;
    height: 40px !important;
    width: 40px !important;
    min-height: 40px;
    min-width: 40px;

    &::part(native) {
      height: 40px;
      width: 40px;
      font-size: 18px;
    }

    ion-icon {
      color: #fff;
      font-size: 24px !important;
    }
  }
  ion-menu-button {
    display: block;
    @include from-tablet {
      display: none;
    }
  }

  .nxu-header__desktop-nav-button {
    display: none;
    @include from-tablet {
      display: flex;
      align-items: center;
      color: $nxu-bg;
      background: transparent;
      padding: 4px $inner-xs;
      border-radius: $nxu-border-radius-sml;
      text-decoration: none;
      font-size: 16px;
      margin-left: $inner-xs;

      &:hover, &:focus {
        background: rgba($nxu-bg, 0.8);
        color: $nxu-bg-contrast;
      }

      ion-icon {
        margin-right: 4px;
        font-size: 18px;
      }
    }
  }
}