@import '../../../styles/variables';

.auth-errored {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    height: 100%;
    padding: $inner-xl;
    background: $nxu-bg;
    color: #fff;
    text-align: center;

    code {
        display: block;
        white-space: normal;
        padding-bottom: $inner-xl;
    }
}