@import '../../../../styles/variables';

.profile-page__details {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin-bottom: $inner-med;

        &:last-child {
            margin-bottom: 0;
        }

        span:first-child {
            display: block;
            font-weight: $nxu-font-weight-heavy;
        }
        span:last-child {
            font-style: italic;
        }
    }

    .profile-page__details--capitalise {
        text-transform: capitalize;
    }
}

.profile-page__referral {
    margin-bottom: $spacing-sml;
}

.profile-page__logout {
    text-transform: capitalize;
    margin-bottom: $spacing-sml;
}