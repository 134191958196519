@import '../../../../styles/variables';

  .success-card-panel {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 480px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    font-weight: 400;

    h1, h2, h3, h4 {
      font-size: 25px;
      font-weight: 400;
      margin-top: $inner-med;
      margin-bottom: $inner-med;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: $inner-med;
      padding: 0 $inner-med;
    }

    .success-icon {
      height: 50px;
      width: 50px;
      margin: $inner-med auto;

      & ~ * {
        margin-top: 0;
      }
    }
  }
