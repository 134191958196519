@import '../../../styles/variables';

.profile-page__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    border: 5px solid $nxu-yellow;
    background: $nxu-border-color;
    margin: $spacing-med auto;

    span {
        font-size: 50px;
        font-weight: $nxu-font-weight-heavy;
        color: $nxu-font-dark-secondary;
    }
}