@import '../../../styles/variables';

.loading-interstitial {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    height: 100%;
    padding: $inner-med;
    background: $nxu-bg;
    color: #fff;
    text-align: center;
}