@import '../../../../styles/variables';

.agreement__doc-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin-bottom: $spacing-sml;

  @include from-mobile-med {
    margin-bottom: $spacing-lrg;
  }

  h1 {
    color: #fff;
  }

  .agreement__welcome {
    font-size: 20px;

    @include from-mobile-med {
      font-size: 24px;
    }
    margin: 0;
  }

  > div {
    display: flex;
    justify-content: flex-end;

    ion-fab-button, button {
      margin: 0 0 0 $inner-xs;

      @include from-mobile-sml {
        margin: 0 0 0 $inner-med;
      }
    }
  }
}